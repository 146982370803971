<template>
  <div class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-4 rounded-lg shadow px-4 pb-4">
    <div class="h-1/6 flex items-center justify-between">
      <div><h5 class="text-gray-500 font-semibold text-md">{{ t("dashboard.relevantPopular",{},{locale: $store.state.Langs.selected.lang}) }}</h5></div>
      <div class="w-auto flex justify-end">
        <!--Right Filter-->
        <v-date-picker v-model="timeline" is-range  :masks="masks" :columns="$screens({ default: 1, sm:1, md:2, lg: 2, xl:2 })">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="flex justify-center items-center rounded border border-gray-200" v-on="inputEvents.end" >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 text-gray-500 mx-1 my-2">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <input v-model="inputValue.start" class="px-1 py-1 w-20 rounded-l focus:outline-none text-xs text-center text-gray-500 hidden sm:block md:hidden lg:block xl:block"/>
              <input :value="inputValue.end"  class="px-1 py-1 w-20 rounded-r focus:outline-none text-xs text-center text-gray-500 hidden sm:block md:hidden lg:block xl:block"/>
            </div>
          </template>
        </v-date-picker>
      </div>
    </div>
    <div class="h-5/6 overflow-y-auto border border-gray-200 divide-y divide-y-100" v-if="data && data.length > 0">
      <div class="w-full flex flex-row justify-start items-start p-2 hover:bg-gray-50 cursor-default" v-for="(item, i) in data" :key="i">
        <div class="w-8 h-12 flex items-center">
          <div class="w-8 h-8 rounded-full bg-gray-600 flex flex-col items-center justify-center">
            <p class="text-white text-sm font-semibold">{{ topRated(i) }}</p>
          </div>
        </div>
        <div class="w-1/6 text-center h-auto">
          <img class="bg-cover bg-center cursor-pointer h-12 mx-auto" :src="item.product.image" :alt="item.product.name">
        </div>
        <div class="w-5/6 flex flex-row items-center justify-between h-12">
          <p class="text-gray-600 text-xs font-semibold uppercase pr-4 truncate" :title="item.product.name">{{ item.product.name }}</p>
          <div class="flex items-center">
            <div class="px-2">
              <span class="text-gray-600 text-sm block text-center font-semibold"> {{ $filters.currency(item.sales ? item.sales : 0) }}</span>
              <span class="text-gray-500 text-xs block text-center uppercase">{{ t("dashboard.sales",{},{locale: $store.state.Langs.selected.lang}) }}</span>
            </div>
            <div class="px-2">
              <span class="text-gray-600 text-sm block text-center font-semibold"> {{ $filters.number( item.quantity ? item.quantity : 0 ) }}</span>
              <span class="text-gray-500 text-xs block text-center uppercase">{{ t("dashboard.total",{},{locale: $store.state.Langs.selected.lang}) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="h-5/6 border border-gray-100 flex flex-col items-center justify-center"
      v-if="$store.state.Dashboard.mostPopularProductsError || !$store.state.Dashboard.isLoadingMostPopularProducts && data.length == 0">
      <div class="space-y-1">
        <h3 class="text-gray-600 text-2xl font-semibold">{{ t("dashboard.noResults",{},{locale: $store.state.Langs.selected.lang}) }}</h3>
        <p class="text-gray-500 text-lg font-semibld">{{ t("dashboard.noData",{},{locale: $store.state.Langs.selected.lang}) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
    import{useI18n} from 'vue-i18n'

    export default {
        name: 'MostPopularProducts',
        props:['date_init', 'date_end', 'type', 'data'],
        emits:['change-dates'],
        setup(){
          const { t, locale } = useI18n();
          return { t , locale }
        },
        data: function(){
            return{
                masks: {
                    input: 'YYYY-MM-DD',
                },
                timeline:{
                   start: this.date_init,
                   end: this.date_end,
                },
                metric:this.type,
                currentProject: this.$route.params.id  ? this.$route.params.id : null,
            }
        },
         created(){

        } ,
        watch:{
            'timeline':{
                handler(newVal){
                    if( newVal ){
                      let newTimeline = {}
                        newTimeline.date_start = this.$moment(this.timeline.start).format('YYYY-MM-DD 00:00:00')
                        newTimeline.date_end = this.$moment(this.timeline.end).format('YYYY-MM-DD 23:59:59')

                        this.$store.dispatch('Calendar/setPopularProducts', newTimeline)
                        this.$emit('change-dates')
                    }
                }
            },
        },
        methods:{
            topRated: function(i){
                return i+1
            }
        }

    }
</script>
