<template>
	<div class="bg-white rounded-lg shadow">
		<div class="flex flex-col px-4 h-full" v-if="$store.state.Dashboard.dashboardActive">
			<div class="w-full h-2/6 flex items-center">
				<h5 class="text-gray-500 uppercase font-semibold text-lg mb-3 border-l-4 pl-2" :class="[type && type == 'Online' ? 'border-blue-500' : 'border-gray-400']">{{ type }}</h5>
			</div>
			<div v-if="type == 'Online'">
				<div class="w-full h-2/6 flex flex-row items-center px-2">
					<div class="w-1/2 h-full items-center justify-start block">
						<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.number(data.total_profiles_online ? data.total_profiles_online : 0) }}</span>
						<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.profile", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
					</div>
					<div class="w-1/2 h-full items-center justify-start block">
						<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.number(data.sales_online ? data.sales_online : 0) }}</span>
						<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
					</div>
				</div>
				<div class="w-full h-2/6 items-center justify-start block rounded-b mt-5 px-2">
					<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.currency(data.revenue_online ? data.revenue_online : 0) }}</span>
					<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.sales", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
				</div>
			</div>
			<div v-if="type == 'Offline'">
				<div class="w-full h-2/6 flex flex-row items-center px-2">
					<div class="w-1/2 h-full items-center justify-start block">
						<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.number(data.total_profiles_offline ? data.total_profiles_offline : 0) }}</span>
						<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.profile", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
					</div>
					<div class="w-1/2 h-full items-center justify-start block">
						<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.number(data.sales_offline ? data.sales_offline : 0) }}</span>
						<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
					</div>
				</div>
				<div class="w-full h-2/6 items-center justify-start block rounded-b mt-4 px-2">
					<span class="text-gray-800 font-semibold truncate text-xl">{{ $filters.currency(data.revenue_offline ? data.revenue_offline : 0) }}</span>
					<p class="text-gray-500 text-xs uppercase">{{ t("dashboard.sales", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useI18n } from "vue-i18n"

export default {
	name: "CardMetrics",
	props: ["data", "type"],
	setup() {
		const { t, locale } = useI18n()
		return { t, locale }
	}
}
</script>

<style lang="scss" scoped></style>
