<template>
  <!-- cards info -->
  <div v-if="info" class="mx-auto rounded-lg divide-y divide-gray-200 sm:divide-y-0 grid-cols-1 grid gap-2 sm:grid-cols-7 sm:gap-4">
    <!-- total customer -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-black uppercase">{{ t("rfm.totalCustomers", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span v-if="totalCustomers != 0" class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(totalCustomers) }}
        </span>
        <div class="my-auto text-sm text-gray-500 uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>100%</p>
          </div>
        </div>
        <small class="text-gray-500"> {{ t("rfm.conditionTotal", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- loyal -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-green-500 h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-white uppercase">{{ t("rfm.loyal", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[0].count) }}
        </span>
        <div class="my-auto text-sm text-white uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[0].percentage }}%</p>
          </div>
        </div>
        <small class="text-white"> {{ t("rfm.conditionLoyal", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- promising -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-green-400 h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-white uppercase">{{ t("rfm.promising", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[2].count) }}
        </span>
        <div class="my-auto text-sm text-white uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[2].percentage }}%</p>
          </div>
        </div>
        <small class="text-white">{{ t("rfm.conditionPromise", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- sleeper -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-red-300 h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-white uppercase">{{ t("rfm.sleeper", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[3].count) }}
        </span>
        <div class="my-auto text-sm text-white uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[3].percentage }}%</p>
          </div>
        </div>
        <small class="text-white">{{ t("rfm.conditionSleeper", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- red alert -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-red-400 h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-white uppercase">{{ t("rfm.redAlert", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[1].count) }}
        </span>
        <div class="my-auto text-sm text-white uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[1].percentage }}%</p>
          </div>
        </div>
        <small class="text-white">{{ t("rfm.conditionRed", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- buyers -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-blue-400 h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-white uppercase">{{ t("rfm.buyers", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[4].count) }}
        </span>
        <div class="my-auto text-sm text-white uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[4].percentage }}%</p>
          </div>
        </div>
        <small class="text-white">{{ t("rfm.conditionOne", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>

    <!-- uncategorized -->
    <div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
      <div class="mt-2 flex justify-between flex-col h-full">
        <h3 class="text-md font-semibold text-black uppercase">{{ t("rfm.uncategorized", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
        <span class="flex flex-row items-center justify-center text-black font-semibold text-opacity-50 my-auto">
          <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-black text-opacity-50" x-description="Heroicon name: solid/users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          {{ $filters.number(info[5].count) }}
        </span>
        <div class="my-auto text-sm text-gray-500 uppercase flex flex-row font-semibold">
          <div class="w-full flex flex-row justify-between text-xs">
            <p>{{ t("rfm.groupSize", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
            <p>{{ info[5].percentage }}%</p>
          </div>
        </div>
        <small class="text-gray-500"> {{ t("rfm.conditionUncat", {}, { locale: $store.state.Langs.selected.lang }) }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import axiosClient from "@/config/AxiosClient"

export default {
  name: "RfmChart",
  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      info: null,
      totalCustomers: 0
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  async created() {
    this.$store.state.Dashboard.isLoadingRfm = true
    let response = await axiosClient.get(`/api/2.0/project/${this.currentProject}/group/rfm`)
    this.info = response.data
    if (response.status == 200 || response.status == 204) {
      this.$store.state.Dashboard.isLoadingRfm = false
    }

    this.totalCustomers = this.info[0].count + this.info[1].count + this.info[2].count + this.info[3].count + this.info[4].count + this.info[5].count
  }
}
</script>

<style scoped>
.vertical {
  writing-mode: vertical-lr;
}
</style>
