<template>
  <div>
    <WrapperDashboard />
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <TransitionFade>
        <div v-if="currentProject">
          <div class="container-xl px-2 py-2 w-full my-4">
            <div class="card-dashed grid grid-cols-12 gap-4">
              <div class="col-span-12 flex flex-row items-center justify-between">
                <h5 class="card-dashed-title mb-0 w-full">{{ t("dashboard.order", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                <div class="w-full flex flex-row items-center justify-end gap-3">
                  <div class="w-20 relative" @mouseleave="setMenuShortCutClose">
                    <div class="rounded-lg shadow bg-white px-2 py-3 text-center text-gray-600 text-sm cursor-pointer inline-flex justify-center items-end" @mouseover="setMenuShortCutOpen">
                      <span>
                        {{ isPlaceHolder }}
                        {{ t("dashboard.days", {}, { locale: $store.state.Langs.selected.lang }) }}
                      </span>
                      <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <!-- Dropdown menu -->
                    <TransitionFade>
                      <div class="absolute pt-2 overflow-y-auto -left-12 shadow-lg z-100 rounded-lg" v-if="$store.state.Calendar.isMenuShortCutsOpen">
                        <ul class="dark:bg-gray-800 rounded-lg divide-y divide-gray-100 bg-white border">
                          <li class="h-10 w-32 text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" :class="[$store.state.Calendar.isShortCutData.length - 1 == i ? 'rounded-b-lg' : '' || i == 0 ? 'rounded-t-lg' : '']" v-for="(sc, i) in $store.state.Calendar.isShortCutData" :key="i" @click="selectShortCutDates(sc)">
                            <span class="font-semibold text-gray-500 inline-block px-4">{{ sc }} {{ t("dashboard.days", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                          </li>
                        </ul>
                      </div>
                    </TransitionFade>
                  </div>
                  <div>
                    <v-date-picker v-model="timeline" is-range :columns="$screens({ default: 1, sm: 1, md: 2, lg: 2, xl: 2 })" :masks="masks">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="shadow w-auto flex justify-center items-center rounded-lg bg-white px-2 py-3" v-on="inputEvents.end">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5 mx-1 text-gray-600">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <input v-model="inputValue.start" class="hidden md:block w-28 rounded-l-lg focus:outline-none text-sm text-center text-gray-600" />
                          <input :value="inputValue.end" class="hidden md:block w-28 rounded-r-lg focus:outline-none text-sm text-center text-gray-600" />
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
              <div class="h-96 bg-white col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-9 rounded-lg shadow p-4" :class="{ 'animate-pulse': $store.state.Dashboard.loading }">
                <TimeLineSkeleton v-if="$store.state.Dashboard.loading" />
                <ECharts ref="chart" :option="timelineChart" v-if="!$store.state.Dashboard.loading && $store.state.Dashboard.dashboardActive" />
              </div>
              <div class="h-96 md:h-48 xl:h-96 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-3 gap-4 grid grid-rows-2 sm:grid-row-2 md:grid-cols-2 md:grid-rows-1 lg:grid-cols-2 lg:grid-rows-1 xl:grid-cols-1 xl:grid-rows-2">
                <CardMetricsSkeleton v-if="$store.state.Dashboard.loading" />
                <CardMetrics v-else :data="$store.state.Dashboard.onlineMetrics" type="Online" />
                <CardMetricsSkeleton v-if="$store.state.Dashboard.loading" />
                <CardMetrics v-else :data="$store.state.Dashboard.offlineMetrics" type="Offline" />
              </div>
            </div>
            <div class="card-dashed">
              <div class="w-full flex flex-col justify-between items-center">
                <h5 class="card-dashed-title mb-0 w-full px-2 py-2">{{ t("dashboard.relevantContent", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                <div class="w-full grid grid-cols-12 gap-4">
                  <TopsCardSkeleton v-if="$store.state.Dashboard.isLoadingMostPopularProducts" />
                  <MostPopularProductsCard v-else :data="$store.state.Dashboard.mostPopularProducts" :date_init="$store.state.Calendar.mostPopularProducts.date_init" :date_end="$store.state.Calendar.mostPopularProducts.date_end" @change-dates="getPopularProducts" type="mostPopularProducts" />
                  <TopsCardSkeleton v-if="$store.state.Dashboard.isLoadingMostRelevantProducts" />
                  <MostRelevantProductsCard v-else :data="$store.state.Dashboard.mostRelevantProducts" :date_init="$store.state.Calendar.mostRelevantProducts.date_init" :date_end="$store.state.Calendar.mostRelevantProducts.date_end" @change-dates="getRelevantProducts" type="mostRelevantProducts" />
                  <TopsCardSkeleton v-if="$store.state.Dashboard.isLoadingMostRelevantCategories" />
                  <MostRelevantCategoriesCard v-else :data="$store.state.Dashboard.mostRelevantCategories" :date_init="$store.state.Calendar.mostRelevantCategories.date_init" :date_end="$store.state.Calendar.mostRelevantCategories.date_end" @change-dates="getRelevantCategories" type="mostRelevantCategories" />
                </div>
              </div>
            </div>
            <div class="card-dashed">
              <div class="w-full flex flex-col justify-between items-center">
                <h5 class="card-dashed-title mb-0 w-full px-2 py-2">{{ t("rfm.title", {}, { locale: $store.state.Langs.selected.lang }) }}</h5>
                <div class="w-full">
                  <RfmChartSkeleton v-if="$store.state.Dashboard.isLoadingRfm" />
                  <RfmChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import WrapperDashboard from "@/components/Dashboard/WrapperDashboard"
import NoContent from "@/components/Utils/NoContent"
import TransitionFade from "@/components/TransitionFade"
import * as echarts from "echarts"
import { h } from "vue"
import { createComponent } from "echarts-for-vue"
import { ChartsConfig } from "@/config/ChartsConfig"
import MostPopularProductsCard from "@/components/Dashboard/MostPopularProductsCard"
import MostRelevantProductsCard from "@/components/Dashboard/MostRelevantProductsCard"
import MostRelevantCategoriesCard from "@/components/Dashboard/MostRelevantCategoriesCard"
import CardMetrics from "@/components/Dashboard/CardMetrics"
import TopsCardSkeleton from "@/components/Dashboard/TopsCardSkeleton"
import CardMetricsSkeleton from "@/components/Dashboard/CardMetricsSkeleton"
import TimeLineSkeleton from "@/components/Dashboard/TimeLineSkeleton"
import RfmChart from "@/components/Dashboard/RfmChart"
import RfmChartSkeleton from "@/components/Dashboard/RfmChartSkeleton"

export default {
  components: {
    CardMetrics,
    WrapperDashboard,
    TransitionFade,
    NoContent,
    ECharts: createComponent({ echarts, h }),
    MostPopularProductsCard,
    MostRelevantProductsCard,
    MostRelevantCategoriesCard,
    TopsCardSkeleton,
    CardMetricsSkeleton,
    TimeLineSkeleton,
    RfmChart,
    RfmChartSkeleton
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  name: `Dashboard`,
  data: function () {
    return {
      isLoading: false,
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      timelineChart: null,
      conversionsOnlineChart: null,
      conversionsOfflineChart: null,
      masks: {
        input: "YYYY-MM-DD"
      },
      timeline: {
        start: this.$store.state.Calendar.timeline.date_start,
        end: this.$store.state.Calendar.timeline.date_end
      },
      isPlaceHolder: 30
    }
  },
  created() {
    if (this.currentProject) {
      this.isLoading = true
      this.handleData(), this.handleMetrics()
    } else this.turnOffSplashScreen(100)
  },
  watch: {
    "$route.params.id": {
      handler(newVal) {
        if (this.currentProject) this.isLoading = true
        if (newVal) {
          this.currentProject = this.$route.params.id //updated currentproject
          this.handleData(), this.handleMetrics()
        }
      }
    },
    timeline: {
      handler(newVal) {
        if (newVal) {
          let newTimeline = {}
          newTimeline.date_start = this.$moment(this.timeline.start).format("YYYY-MM-DD 00:00:00")
          newTimeline.date_end = this.$moment(this.timeline.end).format("YYYY-MM-DD 23:59:59")
          this.$store.dispatch("Calendar/setNewTimeLine", newTimeline)
          this.handleData()
        }
      }
    }
  },
  methods: {
    doSomething: function () {
      this.$refs.chart.inst.getWidth()
    },
    setMenuShortCutOpen: function () {
      this.$store.dispatch("Calendar/openMenuShortCut")
    },
    setMenuShortCutClose: function () {
      this.$store.dispatch("Calendar/closeMenuShortCut")
    },
    selectShortCutDates: function (payload) {
      this.setMenuShortCutClose()
      this.isPlaceHolder = payload
      switch (payload) {
        case 7:
          this.timeline = {
            start: this.$moment(new Date()).subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 14:
          this.timeline = {
            start: this.$moment(new Date()).subtract(14, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break

        case 30:
          this.timeline = {
            start: this.$moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 60:
          this.timeline = {
            start: this.$moment(new Date()).subtract(60, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 90:
          this.timeline = {
            start: this.$moment(new Date()).subtract(90, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        default:
          this.timeline = {
            start: this.$store.state.Calendar.timeline.date_start,
            end: this.$store.state.Calendar.timeline.date_end
          }
          break
      }
    },
    handleData: function () {
      this.getTimeline()
      this.offLineData()
      this.onLineData()
    },
    handleMetrics: function () {
      this.getPopularProducts()
      this.getRelevantProducts()
      this.getRelevantCategories()
    },
    getTimeline: async function () {
      let currentProject = this.currentProject
      const timeline = { date_start: this.$store.state.Calendar.timeline.date_start, date_end: this.$store.state.Calendar.timeline.date_end }
      await this.$store.dispatch("Dashboard/dashboardTimeLine", { currentProject, timeline }).then((res) => {
        if (res.status == 403) {
          this.$toast.error(`${res.data.message}`)
          this.$router.push({
            name: "Projects",
            params: { id: "" }
          })
        }
      })
      this.timelineChart = await ChartsConfig.timelineChart(this.$store.state.Dashboard)
      this.turnOffSplashScreen(1000)
    },
    onLineData: function () {
      let currentProject = this.currentProject
      let filter = {}
      filter.date_from = `${this.$store.state.Calendar.timeline.date_start}`
      filter.date_to = `${this.$store.state.Calendar.timeline.date_end}`
      this.$store.dispatch("Dashboard/getOnlineData", { currentProject, filter })
    },
    offLineData: function () {
      let currentProject = this.currentProject
      let filter = {}
      filter.date_from = `${this.$store.state.Calendar.timeline.date_start}`
      filter.date_to = `${this.$store.state.Calendar.timeline.date_end}`
      this.$store.dispatch("Dashboard/getOfflineData", { currentProject, filter })
    },
    getPopularProducts: function () {
      let currentProject = this.currentProject
      let filter = {}
      filter.date_init = `${this.$store.state.Calendar.mostPopularProducts.date_init}`
      filter.date_end = `${this.$store.state.Calendar.mostPopularProducts.date_end}`
      filter.limit = 10
      this.$store.dispatch("Dashboard/getPopularProducts", { currentProject, filter })
    },
    getRelevantProducts: function () {
      let currentProject = this.currentProject
      let filter = {}
      filter.date_init = `${this.$store.state.Calendar.mostRelevantProducts.date_init}`
      filter.date_end = `${this.$store.state.Calendar.mostRelevantProducts.date_end}`
      filter.limit = 10
      this.$store.dispatch("Dashboard/getRelevantProducts", { currentProject, filter })
    },
    getRelevantCategories: function () {
      let currentProject = this.currentProject
      let filter = {}
      filter.date_init = `${this.$store.state.Calendar.mostRelevantCategories.date_init}`
      filter.date_end = `${this.$store.state.Calendar.mostRelevantCategories.date_end}`
      filter.limit = 10
      this.$store.dispatch("Dashboard/getRelevantCategories", { currentProject, filter })
    },
    turnOffSplashScreen: function (time) {
      setTimeout(() => {
        this.isLoading = false
      }, time)
    }
  }
}
</script>
