import * as echarts from "echarts"

export const ChartsConfig = {
	/**
	 * add config for timline chart form dhasboard and return object whit that config
	 * @param {any} data
	 * @returns {object}
	 */
	timelineChart: function (data) {
		const option = {
			color: ["#3B82F6", "#9ca3af"],
			title: { text: "" },
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross",
					label: { backgroundColor: "#6a7985" }
				}
			},
			legend: { data: ["online", "offline"] },
			grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
			xAxis: [{ type: "category", boundaryGap: false, data: data.timeLineChart.dateRange || [1] }],
			yAxis: [{ type: "value" }],
			series: [
				{
					name: "online",
					type: "line",
					markPoint: {
						data: [{ type: "max", name: "max" }],
						label: {
							color: "#ffffff"
						}
					},
					smooth: true,
					lineStyle: { width: 2, color: "#3B82F6" },
					showSymbol: false,
					areaStyle: {
						opacity: 0.2,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0.5,
								color: "#3B82F6"
							},
							{
								offset: 1,
								color: "#ffffff"
							}
						])
					},
					emphasis: { focus: "series" },
					data: data.timeLineChart.onlineTimeline || [1]
				},
				{
					name: "offline",
					type: "line",
					markPoint: {
						data: [{ type: "max", name: "max" }],
						label: {
							color: "#ffffff"
						}
					},
					smooth: true,
					lineStyle: { width: 2, color: "#9ca3af" },
					showSymbol: false,
					areaStyle: {
						opacity: 0.3,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0.5,
								color: "#9ca3af"
							},
							{
								offset: 1,
								color: "#ffffff"
							}
						])
					},
					emphasis: { focus: "series" },
					data: data.timeLineChart.offlineTimeline || [1]
				}
			]
		}
		return option
	},
	salesChart: function (data) {
		const option = {
			color: ["#3B82F6", "#9ca3af"],
			title: { text: "" },
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "cross",
					label: { backgroundColor: "#6a7985" }
				}
			},
			legend: { data: ["online", "offline"] },
			grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
			xAxis: [{ type: "category", boundaryGap: false, data: data.salesChart.date }],
			yAxis: [{ type: "value" }],
			series: [
				{
					name: "online",
					type: "line",
					markPoint: {
						data: [{ type: "max", name: "max" }],
						label: {
							color: "#ffffff"
						}
					},
					smooth: true,
					lineStyle: { width: 2, color: "#3B82F6" },
					showSymbol: false,
					areaStyle: {
						opacity: 0.2,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0.5,
								color: "#3B82F6"
							},
							{
								offset: 1,
								color: "#ffffff"
							}
						])
					},
					emphasis: { focus: "series" },
					data: data.salesChart.online
				},
				{
					name: "offline",
					type: "line",
					markPoint: {
						data: [{ type: "max", name: "max" }],
						label: {
							color: "#ffffff"
						}
					},
					smooth: true,
					lineStyle: { width: 2, color: "#9ca3af" },
					showSymbol: false,
					areaStyle: {
						opacity: 0.3,
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							{
								offset: 0.5,
								color: "#9ca3af"
							},
							{
								offset: 1,
								color: "#ffffff"
							}
						])
					},
					emphasis: { focus: "series" },
					data: data.salesChart.offline
				},
			]
		}
		return { option }
	}
}
