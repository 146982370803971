<template>
	<div class="w-full">
		<!-- cards info -->
		<div class="mx-auto rounded-lg divide-y divide-gray-200 sm:divide-y-0 grid-cols-1 grid gap-2 sm:grid-cols-7 sm:gap-4">
			<!-- total customer -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- loyal -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- promising -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- sleeper -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- red alert -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- buyers -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>

			<!-- uncategorized -->
			<div class="card rounded-md relative group p-4 shadow-md text-sm lg:text-md bg-white h-56">
				<div class="h-14 w-full bg-gray-100 animate-pulse"></div>
				<div class="h-72 mt-2 bg-gray-100 animate-pulse"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RfmChartSkeleton",
	data() {
		return {}
	}
}
</script>

<style scoped>
.vertical {
	writing-mode: vertical-lr;
}
</style>
