<template>
  <div class="h-96 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-4 rounded-lg shadow p-4 bg-white">
    <div class="h-14 w-full bg-gray-100 animate-pulse"></div>
    <div class="h-72 mt-2 bg-gray-100 animate-pulse">
    </div>
  </div>
</template>

<script>
  export default {
    props:['condition']
  }
</script>

<style lang="scss" scoped>

</style>
