<template>
  <div>
    <div class="border-t bg-white border-b border-gray-200">
      <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <!--Left Filter-->
        <div class="flex flex-row items-center justify-between md:justify-start">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">Dashboard</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WrapperDashboard",
  components: {},
  props: [],
  emits: [],
  data: function () {
    return {}
  },
  watch: {}
}
</script>
