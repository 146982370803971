<template>
	<div class="text-center mt-8">
		<svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
			<path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
		</svg>
		<h3 class="mt-2 text-sm font-medium text-gray-900">{{ t("utils.noProjects", {}, { locale: $store.state.Langs.selected.lang }) }}</h3>
		<p class="mt-1 text-sm text-gray-500">
			{{ t("utils.selectProject", {}, { locale: $store.state.Langs.selected.lang }) }}
		</p>
		<div class="mt-6">
			<router-link :to="{ name: 'Projects', params: { id: '' } }">
				<button type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
					{{ t("utils.goProjects", {}, { locale: $store.state.Langs.selected.lang }) }}
					<ArrowRightIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import { useI18n } from "vue-i18n"
import { ArrowRightIcon } from "@heroicons/vue/solid"
export default {
	name: "NoContent",
	components: {
		ArrowRightIcon
	},
	setup() {
		const { t, locale } = useI18n()
		return { t, locale }
	}
}
</script>




